import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _cef64aea = () => interopDefault(import('../pages/agent_root_path/index.vue' /* webpackChunkName: "pages/agent_root_path/index" */))
const _1bd68eec = () => interopDefault(import('../pages/bank-socket-testing/index.vue' /* webpackChunkName: "pages/bank-socket-testing/index" */))
const _1113df61 = () => interopDefault(import('../pages/csl-support/index.vue' /* webpackChunkName: "pages/csl-support/index" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f09dcd50 = () => interopDefault(import('../pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _33cda1cb = () => interopDefault(import('../pages/no-permissions/index.vue' /* webpackChunkName: "pages/no-permissions/index" */))
const _ab54e91a = () => interopDefault(import('../pages/agent_root_path/banks/index.vue' /* webpackChunkName: "pages/agent_root_path/banks/index" */))
const _7ad0bd07 = () => interopDefault(import('../pages/agent_root_path/error404.vue' /* webpackChunkName: "pages/agent_root_path/error404" */))
const _622c7da2 = () => interopDefault(import('../pages/agent_root_path/login.vue' /* webpackChunkName: "pages/agent_root_path/login" */))
const _dc6f1cc2 = () => interopDefault(import('../pages/agent_root_path/my-profile.vue' /* webpackChunkName: "pages/agent_root_path/my-profile" */))
const _71402d7b = () => interopDefault(import('../pages/agent_root_path/player/index.vue' /* webpackChunkName: "pages/agent_root_path/player/index" */))
const _a7f93f96 = () => interopDefault(import('../pages/agents/agent-settlement/index.vue' /* webpackChunkName: "pages/agents/agent-settlement/index" */))
const _1b491f5c = () => interopDefault(import('../pages/agents/agents/index.vue' /* webpackChunkName: "pages/agents/agents/index" */))
const _611c1a3d = () => interopDefault(import('../pages/bet/bet-history/index.vue' /* webpackChunkName: "pages/bet/bet-history/index" */))
const _22f65176 = () => interopDefault(import('../pages/bet/player-bet/index.vue' /* webpackChunkName: "pages/bet/player-bet/index" */))
const _4d0d9217 = () => interopDefault(import('../pages/finance/currencies/index.vue' /* webpackChunkName: "pages/finance/currencies/index" */))
const _0470f206 = () => interopDefault(import('../pages/finance/deposits/index.vue' /* webpackChunkName: "pages/finance/deposits/index" */))
const _7f27ddb5 = () => interopDefault(import('../pages/finance/exchange-rate.vue' /* webpackChunkName: "pages/finance/exchange-rate" */))
const _2eae43fc = () => interopDefault(import('../pages/finance/manual-adjustment/index.vue' /* webpackChunkName: "pages/finance/manual-adjustment/index" */))
const _5af5244c = () => interopDefault(import('../pages/finance/withdrawals/index.vue' /* webpackChunkName: "pages/finance/withdrawals/index" */))
const _a5745fc2 = () => interopDefault(import('../pages/frontend/cms/index.vue' /* webpackChunkName: "pages/frontend/cms/index" */))
const _04564233 = () => interopDefault(import('../pages/frontend/seo/index.vue' /* webpackChunkName: "pages/frontend/seo/index" */))
const _69cc4c0f = () => interopDefault(import('../pages/marketing-tools/banners/index.vue' /* webpackChunkName: "pages/marketing-tools/banners/index" */))
const _37bba7ac = () => interopDefault(import('../pages/marketing-tools/categories/index.vue' /* webpackChunkName: "pages/marketing-tools/categories/index" */))
const _f431b5f8 = () => interopDefault(import('../pages/marketing-tools/click-record/index.vue' /* webpackChunkName: "pages/marketing-tools/click-record/index" */))
const _780489de = () => interopDefault(import('../pages/marketing-tools/marketing-performance/index.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/index" */))
const _9e280e7e = () => interopDefault(import('../pages/marketing-tools/view-record/index.vue' /* webpackChunkName: "pages/marketing-tools/view-record/index" */))
const _36813d7d = () => interopDefault(import('../pages/operator/ggr-report/index.vue' /* webpackChunkName: "pages/operator/ggr-report/index" */))
const _18d1884f = () => interopDefault(import('../pages/operator/operator-list/index.vue' /* webpackChunkName: "pages/operator/operator-list/index" */))
const _5c808836 = () => interopDefault(import('../pages/operator/operator-report/index.vue' /* webpackChunkName: "pages/operator/operator-report/index" */))
const _0a628260 = () => interopDefault(import('../pages/operator/quota/index.vue' /* webpackChunkName: "pages/operator/quota/index" */))
const _8be9d2aa = () => interopDefault(import('../pages/player-reward/claim/index.vue' /* webpackChunkName: "pages/player-reward/claim/index" */))
const _32ec8b7e = () => interopDefault(import('../pages/player-reward/invite-and-earn/index.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/index" */))
const _542c0c52 = () => interopDefault(import('../pages/player-reward/promotion/index.vue' /* webpackChunkName: "pages/player-reward/promotion/index" */))
const _2435035e = () => interopDefault(import('../pages/player-reward/promotion-code/index.vue' /* webpackChunkName: "pages/player-reward/promotion-code/index" */))
const _36fa190a = () => interopDefault(import('../pages/player-reward/report/index.vue' /* webpackChunkName: "pages/player-reward/report/index" */))
const _231ac154 = () => interopDefault(import('../pages/player-reward/reward-reset-threshold/index.vue' /* webpackChunkName: "pages/player-reward/reward-reset-threshold/index" */))
const _09c24132 = () => interopDefault(import('../pages/player-reward/rolling-settings/index.vue' /* webpackChunkName: "pages/player-reward/rolling-settings/index" */))
const _35c1a11c = () => interopDefault(import('../pages/players/messages/index.vue' /* webpackChunkName: "pages/players/messages/index" */))
const _1517f764 = () => interopDefault(import('../pages/players/missing-players.vue' /* webpackChunkName: "pages/players/missing-players" */))
const _69da1685 = () => interopDefault(import('../pages/players/online-players.vue' /* webpackChunkName: "pages/players/online-players" */))
const _0e9b0010 = () => interopDefault(import('../pages/players/players/index.vue' /* webpackChunkName: "pages/players/players/index" */))
const _7a4bda94 = () => interopDefault(import('../pages/provider/games/index.vue' /* webpackChunkName: "pages/provider/games/index" */))
const _01c05352 = () => interopDefault(import('../pages/provider/providers/index.vue' /* webpackChunkName: "pages/provider/providers/index" */))
const _862c10b8 = () => interopDefault(import('../pages/reports/company-report.vue' /* webpackChunkName: "pages/reports/company-report" */))
const _fe65b69e = () => interopDefault(import('../pages/reports/deposit/index.vue' /* webpackChunkName: "pages/reports/deposit/index" */))
const _2ef087d7 = () => interopDefault(import('../pages/reports/report/index.vue' /* webpackChunkName: "pages/reports/report/index" */))
const _c80b03d0 = () => interopDefault(import('../pages/reports/withdrawal/index.vue' /* webpackChunkName: "pages/reports/withdrawal/index" */))
const _f2991de8 = () => interopDefault(import('../pages/setting/admin-risk-control.vue' /* webpackChunkName: "pages/setting/admin-risk-control" */))
const _61be574f = () => interopDefault(import('../pages/setting/deposit-flow-list.vue' /* webpackChunkName: "pages/setting/deposit-flow-list" */))
const _04ea1f3f = () => interopDefault(import('../pages/setting/language-strings/index.vue' /* webpackChunkName: "pages/setting/language-strings/index" */))
const _3d7d407c = () => interopDefault(import('../pages/setting/languages.vue' /* webpackChunkName: "pages/setting/languages" */))
const _736c006a = () => interopDefault(import('../pages/setting/operation-setting.vue' /* webpackChunkName: "pages/setting/operation-setting" */))
const _de0fa0b4 = () => interopDefault(import('../pages/setting/operator-custom-domain.vue' /* webpackChunkName: "pages/setting/operator-custom-domain" */))
const _179d1774 = () => interopDefault(import('../pages/setting/player-registration.vue' /* webpackChunkName: "pages/setting/player-registration" */))
const _034acc94 = () => interopDefault(import('../pages/setting/promotion-claim-setting.vue' /* webpackChunkName: "pages/setting/promotion-claim-setting" */))
const _b798e16a = () => interopDefault(import('../pages/setting/recaptcha-setting.vue' /* webpackChunkName: "pages/setting/recaptcha-setting" */))
const _9e6ded4e = () => interopDefault(import('../pages/setting/suspend-registration.vue' /* webpackChunkName: "pages/setting/suspend-registration" */))
const _b4208e74 = () => interopDefault(import('../pages/setting/turnover-setting/index.vue' /* webpackChunkName: "pages/setting/turnover-setting/index" */))
const _6ce96005 = () => interopDefault(import('../pages/setting/website-maintenance/index.vue' /* webpackChunkName: "pages/setting/website-maintenance/index" */))
const _a1fcc74e = () => interopDefault(import('../pages/users/roles/index.vue' /* webpackChunkName: "pages/users/roles/index" */))
const _73cc526d = () => interopDefault(import('../pages/users/user-activity/index.vue' /* webpackChunkName: "pages/users/user-activity/index" */))
const _227b39e4 = () => interopDefault(import('../pages/users/users/index.vue' /* webpackChunkName: "pages/users/users/index" */))
const _25c29f96 = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/index.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/index" */))
const _b5a58606 = () => interopDefault(import('../pages/agent_root_path/bet/player-bet/index.vue' /* webpackChunkName: "pages/agent_root_path/bet/player-bet/index" */))
const _641ca678 = () => interopDefault(import('../pages/agent_root_path/finance/deposits/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/index" */))
const _2a2f481b = () => interopDefault(import('../pages/agent_root_path/finance/manual-adjustment/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/manual-adjustment/index" */))
const _989155b6 = () => interopDefault(import('../pages/agent_root_path/finance/withdrawals/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/withdrawals/index" */))
const _430c66ba = () => interopDefault(import('../pages/agent_root_path/player/create.vue' /* webpackChunkName: "pages/agent_root_path/player/create" */))
const _05ebc6e3 = () => interopDefault(import('../pages/agent_root_path/players/messages/index.vue' /* webpackChunkName: "pages/agent_root_path/players/messages/index" */))
const _2a8e9767 = () => interopDefault(import('../pages/agent_root_path/players/missing-players.vue' /* webpackChunkName: "pages/agent_root_path/players/missing-players" */))
const _3a043c4c = () => interopDefault(import('../pages/agent_root_path/players/online-players.vue' /* webpackChunkName: "pages/agent_root_path/players/online-players" */))
const _068cdf15 = () => interopDefault(import('../pages/agent_root_path/players/online-players1.vue' /* webpackChunkName: "pages/agent_root_path/players/online-players1" */))
const _854df55e = () => interopDefault(import('../pages/agent_root_path/players/players/index.vue' /* webpackChunkName: "pages/agent_root_path/players/players/index" */))
const _4573aa0a = () => interopDefault(import('../pages/agent_root_path/reports/deposit/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/deposit/index" */))
const _9db11944 = () => interopDefault(import('../pages/agent_root_path/reports/report/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/report/index" */))
const _0a324e1f = () => interopDefault(import('../pages/agent_root_path/reports/withdrawal/index.vue' /* webpackChunkName: "pages/agent_root_path/reports/withdrawal/index" */))
const _66e2f974 = () => interopDefault(import('../pages/agent_root_path/users/user-activity/index.vue' /* webpackChunkName: "pages/agent_root_path/users/user-activity/index" */))
const _176453bd = () => interopDefault(import('../pages/agent_root_path/users/users/index.vue' /* webpackChunkName: "pages/agent_root_path/users/users/index" */))
const _fe7236a8 = () => interopDefault(import('../pages/agents/agents/create.vue' /* webpackChunkName: "pages/agents/agents/create" */))
const _d56458ac = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/index.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/index" */))
const _01a0b4d4 = () => interopDefault(import('../pages/finance/bank-management/log.vue' /* webpackChunkName: "pages/finance/bank-management/log" */))
const _861c251e = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/index.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/index" */))
const _b4de0ea8 = () => interopDefault(import('../pages/finance/cryptocurrency/log.vue' /* webpackChunkName: "pages/finance/cryptocurrency/log" */))
const _350e2946 = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/index.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/index" */))
const _c6dc5602 = () => interopDefault(import('../pages/finance/deposits/manual-deposit/index.vue' /* webpackChunkName: "pages/finance/deposits/manual-deposit/index" */))
const _f1602448 = () => interopDefault(import('../pages/finance/third-party-payment/log/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/log/index" */))
const _471ecd98 = () => interopDefault(import('../pages/finance/third-party-payment/third-party/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/index" */))
const _7c3101f1 = () => interopDefault(import('../pages/finance/third-party-payment/third-party-payment-account/index.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party-payment-account/index" */))
const _7f3b89ae = () => interopDefault(import('../pages/frontend/cms/add.vue' /* webpackChunkName: "pages/frontend/cms/add" */))
const _111cfee2 = () => interopDefault(import('../pages/marketing-tools/banners/create.vue' /* webpackChunkName: "pages/marketing-tools/banners/create" */))
const _2913cc4f = () => interopDefault(import('../pages/operator/operator-list/create.vue' /* webpackChunkName: "pages/operator/operator-list/create" */))
const _cff1af02 = () => interopDefault(import('../pages/player-reward/invite-and-earn/referral-rate.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/referral-rate" */))
const _ebc099c0 = () => interopDefault(import('../pages/player-reward/promotion-code/create.vue' /* webpackChunkName: "pages/player-reward/promotion-code/create" */))
const _5909c8ac = () => interopDefault(import('../pages/player-reward/promotion/create.vue' /* webpackChunkName: "pages/player-reward/promotion/create" */))
const _abb265bc = () => interopDefault(import('../pages/players/messages/create.vue' /* webpackChunkName: "pages/players/messages/create" */))
const _4551c9c6 = () => interopDefault(import('../pages/players/players/create.vue' /* webpackChunkName: "pages/players/players/create" */))
const _7fe3d942 = () => interopDefault(import('../pages/setting/language-strings/create.vue' /* webpackChunkName: "pages/setting/language-strings/create" */))
const _58e63985 = () => interopDefault(import('../pages/users/roles/create.vue' /* webpackChunkName: "pages/users/roles/create" */))
const _54a04d5a = () => interopDefault(import('../pages/users/users/create.vue' /* webpackChunkName: "pages/users/users/create" */))
const _bec6ef50 = () => interopDefault(import('../pages/agent_root_path/finance/deposits/manual-deposit/index.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/manual-deposit/index" */))
const _417d3f8a = () => interopDefault(import('../pages/agent_root_path/players/messages/create.vue' /* webpackChunkName: "pages/agent_root_path/players/messages/create" */))
const _08f43906 = () => interopDefault(import('../pages/agent_root_path/players/messages/create1.vue' /* webpackChunkName: "pages/agent_root_path/players/messages/create1" */))
const _157bef8d = () => interopDefault(import('../pages/agent_root_path/players/players/create.vue' /* webpackChunkName: "pages/agent_root_path/players/players/create" */))
const _73d08a43 = () => interopDefault(import('../pages/agent_root_path/reports/report/index1.vue' /* webpackChunkName: "pages/agent_root_path/reports/report/index1" */))
const _064b22be = () => interopDefault(import('../pages/agent_root_path/users/users/create.vue' /* webpackChunkName: "pages/agent_root_path/users/users/create" */))
const _3c20ec54 = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/create.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/create" */))
const _09000b6d = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/create.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/create" */))
const _1d4e69fe = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/create.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/create" */))
const _31a386ed = () => interopDefault(import('../pages/finance/third-party-payment/third-party-payment-account/create.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party-payment-account/create" */))
const _77219fb4 = () => interopDefault(import('../pages/finance/third-party-payment/third-party/create.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/create" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1b0b09e8 = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/detail/_id.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/detail/_id" */))
const _4ed00cba = () => interopDefault(import('../pages/agent_root_path/bet/bet-history/sub-bet-history/_id.vue' /* webpackChunkName: "pages/agent_root_path/bet/bet-history/sub-bet-history/_id" */))
const _9489ed0c = () => interopDefault(import('../pages/agent_root_path/finance/deposits/detail/_id.vue' /* webpackChunkName: "pages/agent_root_path/finance/deposits/detail/_id" */))
const _138ea21a = () => interopDefault(import('../pages/agent_root_path/users/users/edit/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/users/edit/_id" */))
const _c9a701e6 = () => interopDefault(import('../pages/finance/bank-management/bank-accounts/edit/_id.vue' /* webpackChunkName: "pages/finance/bank-management/bank-accounts/edit/_id" */))
const _a67f8834 = () => interopDefault(import('../pages/finance/bank-management/self-managed-payment-gateway/edit/_id.vue' /* webpackChunkName: "pages/finance/bank-management/self-managed-payment-gateway/edit/_id" */))
const _3c31330c = () => interopDefault(import('../pages/finance/cryptocurrency/wallet-list/edit/_id.vue' /* webpackChunkName: "pages/finance/cryptocurrency/wallet-list/edit/_id" */))
const _48f9165f = () => interopDefault(import('../pages/finance/third-party-payment/third-party/edit/_id.vue' /* webpackChunkName: "pages/finance/third-party-payment/third-party/edit/_id" */))
const _3340161a = () => interopDefault(import('../pages/frontend/cms/submenu/create/_id.vue' /* webpackChunkName: "pages/frontend/cms/submenu/create/_id" */))
const _094bb00e = () => interopDefault(import('../pages/agent_root_path/players/players/_id.vue' /* webpackChunkName: "pages/agent_root_path/players/players/_id" */))
const _6ff46908 = () => interopDefault(import('../pages/agent_root_path/players/players/_id1.vue' /* webpackChunkName: "pages/agent_root_path/players/players/_id1" */))
const _235ec1a4 = () => interopDefault(import('../pages/agent_root_path/users/user-activity/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/user-activity/_id" */))
const _e303bd36 = () => interopDefault(import('../pages/agent_root_path/users/users/_id.vue' /* webpackChunkName: "pages/agent_root_path/users/users/_id" */))
const _4e35c165 = () => interopDefault(import('../pages/agents/agents/edit/_id.vue' /* webpackChunkName: "pages/agents/agents/edit/_id" */))
const _36d7f88f = () => interopDefault(import('../pages/agents/agents/report/_id.vue' /* webpackChunkName: "pages/agents/agents/report/_id" */))
const _5b871fa1 = () => interopDefault(import('../pages/bet/bet-history/detail/_id.vue' /* webpackChunkName: "pages/bet/bet-history/detail/_id" */))
const _4f1116ac = () => interopDefault(import('../pages/frontend/cms/create/_id.vue' /* webpackChunkName: "pages/frontend/cms/create/_id" */))
const _42cf9b10 = () => interopDefault(import('../pages/frontend/cms/edit/_id.vue' /* webpackChunkName: "pages/frontend/cms/edit/_id" */))
const _1abf2a52 = () => interopDefault(import('../pages/frontend/cms/submenu/_id.vue' /* webpackChunkName: "pages/frontend/cms/submenu/_id" */))
const _76ae1ef0 = () => interopDefault(import('../pages/marketing-tools/banners/edit/_id.vue' /* webpackChunkName: "pages/marketing-tools/banners/edit/_id" */))
const _8741f1f0 = () => interopDefault(import('../pages/marketing-tools/marketing-performance/associate/_id.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/associate/_id" */))
const _15d96236 = () => interopDefault(import('../pages/marketing-tools/marketing-performance/direct/_id.vue' /* webpackChunkName: "pages/marketing-tools/marketing-performance/direct/_id" */))
const _5aba4b40 = () => interopDefault(import('../pages/operator/operator-list/bet-history/_id.vue' /* webpackChunkName: "pages/operator/operator-list/bet-history/_id" */))
const _16e74c48 = () => interopDefault(import('../pages/operator/operator-list/edit/_id.vue' /* webpackChunkName: "pages/operator/operator-list/edit/_id" */))
const _7086ebcc = () => interopDefault(import('../pages/operator/operator-list/players/_id.vue' /* webpackChunkName: "pages/operator/operator-list/players/_id" */))
const _89b56406 = () => interopDefault(import('../pages/operator/quota/log/_id.vue' /* webpackChunkName: "pages/operator/quota/log/_id" */))
const _214fa565 = () => interopDefault(import('../pages/player-reward/promotion/edit/_id.vue' /* webpackChunkName: "pages/player-reward/promotion/edit/_id" */))
const _dd5092da = () => interopDefault(import('../pages/users/users/edit/_id.vue' /* webpackChunkName: "pages/users/users/edit/_id" */))
const _24c9fb93 = () => interopDefault(import('../pages/player-reward/invite-and-earn/players/_slug/_ip.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/players/_slug/_ip" */))
const _4294a207 = () => interopDefault(import('../pages/player-reward/invite-and-earn/report/_slug/_ip.vue' /* webpackChunkName: "pages/player-reward/invite-and-earn/report/_slug/_ip" */))
const _25484546 = () => interopDefault(import('../pages/player-reward/promotion-code/_id.vue' /* webpackChunkName: "pages/player-reward/promotion-code/_id" */))
const _c75fbf40 = () => interopDefault(import('../pages/players/players/_id.vue' /* webpackChunkName: "pages/players/players/_id" */))
const _02663dfe = () => interopDefault(import('../pages/users/roles/_id.vue' /* webpackChunkName: "pages/users/roles/_id" */))
const _8d93e7d6 = () => interopDefault(import('../pages/users/user-activity/_id.vue' /* webpackChunkName: "pages/users/user-activity/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/agent_root_path",
    component: _cef64aea,
    name: "agent_root_path"
  }, {
    path: "/bank-socket-testing",
    component: _1bd68eec,
    name: "bank-socket-testing"
  }, {
    path: "/csl-support",
    component: _1113df61,
    name: "csl-support"
  }, {
    path: "/login",
    component: _57062649,
    name: "login"
  }, {
    path: "/my-profile",
    component: _f09dcd50,
    name: "my-profile"
  }, {
    path: "/no-permissions",
    component: _33cda1cb,
    name: "no-permissions"
  }, {
    path: "/agent_root_path/banks",
    component: _ab54e91a,
    name: "agent_root_path-banks"
  }, {
    path: "/agent_root_path/error404",
    component: _7ad0bd07,
    name: "agent_root_path-error404"
  }, {
    path: "/agent_root_path/login",
    component: _622c7da2,
    name: "agent_root_path-login"
  }, {
    path: "/agent_root_path/my-profile",
    component: _dc6f1cc2,
    name: "agent_root_path-my-profile"
  }, {
    path: "/agent_root_path/player",
    component: _71402d7b,
    name: "agent_root_path-player"
  }, {
    path: "/agents/agent-settlement",
    component: _a7f93f96,
    name: "agents-agent-settlement"
  }, {
    path: "/agents/agents",
    component: _1b491f5c,
    name: "agents-agents"
  }, {
    path: "/bet/bet-history",
    component: _611c1a3d,
    name: "bet-bet-history"
  }, {
    path: "/bet/player-bet",
    component: _22f65176,
    name: "bet-player-bet"
  }, {
    path: "/finance/currencies",
    component: _4d0d9217,
    name: "finance-currencies"
  }, {
    path: "/finance/deposits",
    component: _0470f206,
    name: "finance-deposits"
  }, {
    path: "/finance/exchange-rate",
    component: _7f27ddb5,
    name: "finance-exchange-rate"
  }, {
    path: "/finance/manual-adjustment",
    component: _2eae43fc,
    name: "finance-manual-adjustment"
  }, {
    path: "/finance/withdrawals",
    component: _5af5244c,
    name: "finance-withdrawals"
  }, {
    path: "/frontend/cms",
    component: _a5745fc2,
    name: "frontend-cms"
  }, {
    path: "/frontend/seo",
    component: _04564233,
    name: "frontend-seo"
  }, {
    path: "/marketing-tools/banners",
    component: _69cc4c0f,
    name: "marketing-tools-banners"
  }, {
    path: "/marketing-tools/categories",
    component: _37bba7ac,
    name: "marketing-tools-categories"
  }, {
    path: "/marketing-tools/click-record",
    component: _f431b5f8,
    name: "marketing-tools-click-record"
  }, {
    path: "/marketing-tools/marketing-performance",
    component: _780489de,
    name: "marketing-tools-marketing-performance"
  }, {
    path: "/marketing-tools/view-record",
    component: _9e280e7e,
    name: "marketing-tools-view-record"
  }, {
    path: "/operator/ggr-report",
    component: _36813d7d,
    name: "operator-ggr-report"
  }, {
    path: "/operator/operator-list",
    component: _18d1884f,
    name: "operator-operator-list"
  }, {
    path: "/operator/operator-report",
    component: _5c808836,
    name: "operator-operator-report"
  }, {
    path: "/operator/quota",
    component: _0a628260,
    name: "operator-quota"
  }, {
    path: "/player-reward/claim",
    component: _8be9d2aa,
    name: "player-reward-claim"
  }, {
    path: "/player-reward/invite-and-earn",
    component: _32ec8b7e,
    name: "player-reward-invite-and-earn"
  }, {
    path: "/player-reward/promotion",
    component: _542c0c52,
    name: "player-reward-promotion"
  }, {
    path: "/player-reward/promotion-code",
    component: _2435035e,
    name: "player-reward-promotion-code"
  }, {
    path: "/player-reward/report",
    component: _36fa190a,
    name: "player-reward-report"
  }, {
    path: "/player-reward/reward-reset-threshold",
    component: _231ac154,
    name: "player-reward-reward-reset-threshold"
  }, {
    path: "/player-reward/rolling-settings",
    component: _09c24132,
    name: "player-reward-rolling-settings"
  }, {
    path: "/players/messages",
    component: _35c1a11c,
    name: "players-messages"
  }, {
    path: "/players/missing-players",
    component: _1517f764,
    name: "players-missing-players"
  }, {
    path: "/players/online-players",
    component: _69da1685,
    name: "players-online-players"
  }, {
    path: "/players/players",
    component: _0e9b0010,
    name: "players-players"
  }, {
    path: "/provider/games",
    component: _7a4bda94,
    name: "provider-games"
  }, {
    path: "/provider/providers",
    component: _01c05352,
    name: "provider-providers"
  }, {
    path: "/reports/company-report",
    component: _862c10b8,
    name: "reports-company-report"
  }, {
    path: "/reports/deposit",
    component: _fe65b69e,
    name: "reports-deposit"
  }, {
    path: "/reports/report",
    component: _2ef087d7,
    name: "reports-report"
  }, {
    path: "/reports/withdrawal",
    component: _c80b03d0,
    name: "reports-withdrawal"
  }, {
    path: "/setting/admin-risk-control",
    component: _f2991de8,
    name: "setting-admin-risk-control"
  }, {
    path: "/setting/deposit-flow-list",
    component: _61be574f,
    name: "setting-deposit-flow-list"
  }, {
    path: "/setting/language-strings",
    component: _04ea1f3f,
    name: "setting-language-strings"
  }, {
    path: "/setting/languages",
    component: _3d7d407c,
    name: "setting-languages"
  }, {
    path: "/setting/operation-setting",
    component: _736c006a,
    name: "setting-operation-setting"
  }, {
    path: "/setting/operator-custom-domain",
    component: _de0fa0b4,
    name: "setting-operator-custom-domain"
  }, {
    path: "/setting/player-registration",
    component: _179d1774,
    name: "setting-player-registration"
  }, {
    path: "/setting/promotion-claim-setting",
    component: _034acc94,
    name: "setting-promotion-claim-setting"
  }, {
    path: "/setting/recaptcha-setting",
    component: _b798e16a,
    name: "setting-recaptcha-setting"
  }, {
    path: "/setting/suspend-registration",
    component: _9e6ded4e,
    name: "setting-suspend-registration"
  }, {
    path: "/setting/turnover-setting",
    component: _b4208e74,
    name: "setting-turnover-setting"
  }, {
    path: "/setting/website-maintenance",
    component: _6ce96005,
    name: "setting-website-maintenance"
  }, {
    path: "/users/roles",
    component: _a1fcc74e,
    name: "users-roles"
  }, {
    path: "/users/user-activity",
    component: _73cc526d,
    name: "users-user-activity"
  }, {
    path: "/users/users",
    component: _227b39e4,
    name: "users-users"
  }, {
    path: "/agent_root_path/bet/bet-history",
    component: _25c29f96,
    name: "agent_root_path-bet-bet-history"
  }, {
    path: "/agent_root_path/bet/player-bet",
    component: _b5a58606,
    name: "agent_root_path-bet-player-bet"
  }, {
    path: "/agent_root_path/finance/deposits",
    component: _641ca678,
    name: "agent_root_path-finance-deposits"
  }, {
    path: "/agent_root_path/finance/manual-adjustment",
    component: _2a2f481b,
    name: "agent_root_path-finance-manual-adjustment"
  }, {
    path: "/agent_root_path/finance/withdrawals",
    component: _989155b6,
    name: "agent_root_path-finance-withdrawals"
  }, {
    path: "/agent_root_path/player/create",
    component: _430c66ba,
    name: "agent_root_path-player-create"
  }, {
    path: "/agent_root_path/players/messages",
    component: _05ebc6e3,
    name: "agent_root_path-players-messages"
  }, {
    path: "/agent_root_path/players/missing-players",
    component: _2a8e9767,
    name: "agent_root_path-players-missing-players"
  }, {
    path: "/agent_root_path/players/online-players",
    component: _3a043c4c,
    name: "agent_root_path-players-online-players"
  }, {
    path: "/agent_root_path/players/online-players1",
    component: _068cdf15,
    name: "agent_root_path-players-online-players1"
  }, {
    path: "/agent_root_path/players/players",
    component: _854df55e,
    name: "agent_root_path-players-players"
  }, {
    path: "/agent_root_path/reports/deposit",
    component: _4573aa0a,
    name: "agent_root_path-reports-deposit"
  }, {
    path: "/agent_root_path/reports/report",
    component: _9db11944,
    name: "agent_root_path-reports-report"
  }, {
    path: "/agent_root_path/reports/withdrawal",
    component: _0a324e1f,
    name: "agent_root_path-reports-withdrawal"
  }, {
    path: "/agent_root_path/users/user-activity",
    component: _66e2f974,
    name: "agent_root_path-users-user-activity"
  }, {
    path: "/agent_root_path/users/users",
    component: _176453bd,
    name: "agent_root_path-users-users"
  }, {
    path: "/agents/agents/create",
    component: _fe7236a8,
    name: "agents-agents-create"
  }, {
    path: "/finance/bank-management/bank-accounts",
    component: _d56458ac,
    name: "finance-bank-management-bank-accounts"
  }, {
    path: "/finance/bank-management/log",
    component: _01a0b4d4,
    name: "finance-bank-management-log"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway",
    component: _861c251e,
    name: "finance-bank-management-self-managed-payment-gateway"
  }, {
    path: "/finance/cryptocurrency/log",
    component: _b4de0ea8,
    name: "finance-cryptocurrency-log"
  }, {
    path: "/finance/cryptocurrency/wallet-list",
    component: _350e2946,
    name: "finance-cryptocurrency-wallet-list"
  }, {
    path: "/finance/deposits/manual-deposit",
    component: _c6dc5602,
    name: "finance-deposits-manual-deposit"
  }, {
    path: "/finance/third-party-payment/log",
    component: _f1602448,
    name: "finance-third-party-payment-log"
  }, {
    path: "/finance/third-party-payment/third-party",
    component: _471ecd98,
    name: "finance-third-party-payment-third-party"
  }, {
    path: "/finance/third-party-payment/third-party-payment-account",
    component: _7c3101f1,
    name: "finance-third-party-payment-third-party-payment-account"
  }, {
    path: "/frontend/cms/add",
    component: _7f3b89ae,
    name: "frontend-cms-add"
  }, {
    path: "/marketing-tools/banners/create",
    component: _111cfee2,
    name: "marketing-tools-banners-create"
  }, {
    path: "/operator/operator-list/create",
    component: _2913cc4f,
    name: "operator-operator-list-create"
  }, {
    path: "/player-reward/invite-and-earn/referral-rate",
    component: _cff1af02,
    name: "player-reward-invite-and-earn-referral-rate"
  }, {
    path: "/player-reward/promotion-code/create",
    component: _ebc099c0,
    name: "player-reward-promotion-code-create"
  }, {
    path: "/player-reward/promotion/create",
    component: _5909c8ac,
    name: "player-reward-promotion-create"
  }, {
    path: "/players/messages/create",
    component: _abb265bc,
    name: "players-messages-create"
  }, {
    path: "/players/players/create",
    component: _4551c9c6,
    name: "players-players-create"
  }, {
    path: "/setting/language-strings/create",
    component: _7fe3d942,
    name: "setting-language-strings-create"
  }, {
    path: "/users/roles/create",
    component: _58e63985,
    name: "users-roles-create"
  }, {
    path: "/users/users/create",
    component: _54a04d5a,
    name: "users-users-create"
  }, {
    path: "/agent_root_path/finance/deposits/manual-deposit",
    component: _bec6ef50,
    name: "agent_root_path-finance-deposits-manual-deposit"
  }, {
    path: "/agent_root_path/players/messages/create",
    component: _417d3f8a,
    name: "agent_root_path-players-messages-create"
  }, {
    path: "/agent_root_path/players/messages/create1",
    component: _08f43906,
    name: "agent_root_path-players-messages-create1"
  }, {
    path: "/agent_root_path/players/players/create",
    component: _157bef8d,
    name: "agent_root_path-players-players-create"
  }, {
    path: "/agent_root_path/reports/report/index1",
    component: _73d08a43,
    name: "agent_root_path-reports-report-index1"
  }, {
    path: "/agent_root_path/users/users/create",
    component: _064b22be,
    name: "agent_root_path-users-users-create"
  }, {
    path: "/finance/bank-management/bank-accounts/create",
    component: _3c20ec54,
    name: "finance-bank-management-bank-accounts-create"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway/create",
    component: _09000b6d,
    name: "finance-bank-management-self-managed-payment-gateway-create"
  }, {
    path: "/finance/cryptocurrency/wallet-list/create",
    component: _1d4e69fe,
    name: "finance-cryptocurrency-wallet-list-create"
  }, {
    path: "/finance/third-party-payment/third-party-payment-account/create",
    component: _31a386ed,
    name: "finance-third-party-payment-third-party-payment-account-create"
  }, {
    path: "/finance/third-party-payment/third-party/create",
    component: _77219fb4,
    name: "finance-third-party-payment-third-party-create"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/agent_root_path/bet/bet-history/detail/:id",
    component: _1b0b09e8,
    name: "agent_root_path-bet-bet-history-detail-id"
  }, {
    path: "/agent_root_path/bet/bet-history/sub-bet-history/:id",
    component: _4ed00cba,
    name: "agent_root_path-bet-bet-history-sub-bet-history-id"
  }, {
    path: "/agent_root_path/finance/deposits/detail/:id?",
    component: _9489ed0c,
    name: "agent_root_path-finance-deposits-detail-id"
  }, {
    path: "/agent_root_path/users/users/edit/:id?",
    component: _138ea21a,
    name: "agent_root_path-users-users-edit-id"
  }, {
    path: "/finance/bank-management/bank-accounts/edit/:id?",
    component: _c9a701e6,
    name: "finance-bank-management-bank-accounts-edit-id"
  }, {
    path: "/finance/bank-management/self-managed-payment-gateway/edit/:id?",
    component: _a67f8834,
    name: "finance-bank-management-self-managed-payment-gateway-edit-id"
  }, {
    path: "/finance/cryptocurrency/wallet-list/edit/:id",
    component: _3c31330c,
    name: "finance-cryptocurrency-wallet-list-edit-id"
  }, {
    path: "/finance/third-party-payment/third-party/edit/:id?",
    component: _48f9165f,
    name: "finance-third-party-payment-third-party-edit-id"
  }, {
    path: "/frontend/cms/submenu/create/:id?",
    component: _3340161a,
    name: "frontend-cms-submenu-create-id"
  }, {
    path: "/agent_root_path/players/players/:id",
    component: _094bb00e,
    name: "agent_root_path-players-players-id"
  }, {
    path: "/agent_root_path/players/players/:id1",
    component: _6ff46908,
    name: "agent_root_path-players-players-id1"
  }, {
    path: "/agent_root_path/users/user-activity/:id?",
    component: _235ec1a4,
    name: "agent_root_path-users-user-activity-id"
  }, {
    path: "/agent_root_path/users/users/:id",
    component: _e303bd36,
    name: "agent_root_path-users-users-id"
  }, {
    path: "/agents/agents/edit/:id?",
    component: _4e35c165,
    name: "agents-agents-edit-id"
  }, {
    path: "/agents/agents/report/:id?",
    component: _36d7f88f,
    name: "agents-agents-report-id"
  }, {
    path: "/bet/bet-history/detail/:id",
    component: _5b871fa1,
    name: "bet-bet-history-detail-id"
  }, {
    path: "/frontend/cms/create/:id?",
    component: _4f1116ac,
    name: "frontend-cms-create-id"
  }, {
    path: "/frontend/cms/edit/:id?",
    component: _42cf9b10,
    name: "frontend-cms-edit-id"
  }, {
    path: "/frontend/cms/submenu/:id?",
    component: _1abf2a52,
    name: "frontend-cms-submenu-id"
  }, {
    path: "/marketing-tools/banners/edit/:id",
    component: _76ae1ef0,
    name: "marketing-tools-banners-edit-id"
  }, {
    path: "/marketing-tools/marketing-performance/associate/:id?",
    component: _8741f1f0,
    name: "marketing-tools-marketing-performance-associate-id"
  }, {
    path: "/marketing-tools/marketing-performance/direct/:id?",
    component: _15d96236,
    name: "marketing-tools-marketing-performance-direct-id"
  }, {
    path: "/operator/operator-list/bet-history/:id",
    component: _5aba4b40,
    name: "operator-operator-list-bet-history-id"
  }, {
    path: "/operator/operator-list/edit/:id",
    component: _16e74c48,
    name: "operator-operator-list-edit-id"
  }, {
    path: "/operator/operator-list/players/:id",
    component: _7086ebcc,
    name: "operator-operator-list-players-id"
  }, {
    path: "/operator/quota/log/:id?",
    component: _89b56406,
    name: "operator-quota-log-id"
  }, {
    path: "/player-reward/promotion/edit/:id",
    component: _214fa565,
    name: "player-reward-promotion-edit-id"
  }, {
    path: "/users/users/edit/:id?",
    component: _dd5092da,
    name: "users-users-edit-id"
  }, {
    path: "/player-reward/invite-and-earn/players/:slug?/:ip?",
    component: _24c9fb93,
    name: "player-reward-invite-and-earn-players-slug-ip"
  }, {
    path: "/player-reward/invite-and-earn/report/:slug?/:ip?",
    component: _4294a207,
    name: "player-reward-invite-and-earn-report-slug-ip"
  }, {
    path: "/player-reward/promotion-code/:id?",
    component: _25484546,
    name: "player-reward-promotion-code-id"
  }, {
    path: "/players/players/:id",
    component: _c75fbf40,
    name: "players-players-id"
  }, {
    path: "/users/roles/:id",
    component: _02663dfe,
    name: "users-roles-id"
  }, {
    path: "/users/user-activity/:id?",
    component: _8d93e7d6,
    name: "users-user-activity-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
